@font-face {
    font-family: 'Cormorant Upright';
    src: url('../fonts/CormorantUpright-Bold.eot');
    src: url('../fonts/CormorantUpright-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantUpright-Bold.woff2') format('woff2'),
        url('../fonts/CormorantUpright-Bold.woff') format('woff'),
        url('../fonts/CormorantUpright-Bold.ttf') format('truetype'),
        url('../fonts/CormorantUpright-Bold.svg#CormorantUpright-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Upright';
    src: url('../fonts/CormorantUpright-Regular.eot');
    src: url('../fonts/CormorantUpright-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantUpright-Regular.woff2') format('woff2'),
        url('../fonts/CormorantUpright-Regular.woff') format('woff'),
        url('../fonts/CormorantUpright-Regular.ttf') format('truetype'),
        url('../fonts/CormorantUpright-Regular.svg#CormorantUpright-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Upright';
    src: url('../fonts/CormorantUpright-Medium.eot');
    src: url('../fonts/CormorantUpright-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantUpright-Medium.woff2') format('woff2'),
        url('../fonts/CormorantUpright-Medium.woff') format('woff'),
        url('../fonts/CormorantUpright-Medium.ttf') format('truetype'),
        url('../fonts/CormorantUpright-Medium.svg#CormorantUpright-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Upright';
    src: url('../fonts/CormorantUpright-Light.eot');
    src: url('../fonts/CormorantUpright-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantUpright-Light.woff2') format('woff2'),
        url('../fonts/CormorantUpright-Light.woff') format('woff'),
        url('../fonts/CormorantUpright-Light.ttf') format('truetype'),
        url('../fonts/CormorantUpright-Light.svg#CormorantUpright-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Upright';
    src: url('../fonts/CormorantUpright-SemiBold.eot');
    src: url('../fonts/CormorantUpright-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantUpright-SemiBold.woff2') format('woff2'),
        url('../fonts/CormorantUpright-SemiBold.woff') format('woff'),
        url('../fonts/CormorantUpright-SemiBold.ttf') format('truetype'),
        url('../fonts/CormorantUpright-SemiBold.svg#CormorantUpright-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-Black.eot');
    src: url('../fonts/Inconsolata-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-Black.woff2') format('woff2'),
        url('../fonts/Inconsolata-Black.woff') format('woff'),
        url('../fonts/Inconsolata-Black.ttf') format('truetype'),
        url('../fonts/Inconsolata-Black.svg#Inconsolata-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-Bold.eot');
    src: url('../fonts/Inconsolata-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-Bold.woff2') format('woff2'),
        url('../fonts/Inconsolata-Bold.woff') format('woff'),
        url('../fonts/Inconsolata-Bold.ttf') format('truetype'),
        url('../fonts/Inconsolata-Bold.svg#Inconsolata-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-ExtraBold.eot');
    src: url('../fonts/Inconsolata-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-ExtraBold.woff2') format('woff2'),
        url('../fonts/Inconsolata-ExtraBold.woff') format('woff'),
        url('../fonts/Inconsolata-ExtraBold.ttf') format('truetype'),
        url('../fonts/Inconsolata-ExtraBold.svg#Inconsolata-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-ExtraLight.eot');
    src: url('../fonts/Inconsolata-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-ExtraLight.woff2') format('woff2'),
        url('../fonts/Inconsolata-ExtraLight.woff') format('woff'),
        url('../fonts/Inconsolata-ExtraLight.ttf') format('truetype'),
        url('../fonts/Inconsolata-ExtraLight.svg#Inconsolata-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-Medium.eot');
    src: url('../fonts/Inconsolata-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-Medium.woff2') format('woff2'),
        url('../fonts/Inconsolata-Medium.woff') format('woff'),
        url('../fonts/Inconsolata-Medium.ttf') format('truetype'),
        url('../fonts/Inconsolata-Medium.svg#Inconsolata-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-Light.eot');
    src: url('../fonts/Inconsolata-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-Light.woff2') format('woff2'),
        url('../fonts/Inconsolata-Light.woff') format('woff'),
        url('../fonts/Inconsolata-Light.ttf') format('truetype'),
        url('../fonts/Inconsolata-Light.svg#Inconsolata-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-Regular.eot');
    src: url('../fonts/Inconsolata-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-Regular.woff2') format('woff2'),
        url('../fonts/Inconsolata-Regular.woff') format('woff'),
        url('../fonts/Inconsolata-Regular.ttf') format('truetype'),
        url('../fonts/Inconsolata-Regular.svg#Inconsolata-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-SemiBold.eot');
    src: url('../fonts/Inconsolata-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inconsolata-SemiBold.woff2') format('woff2'),
        url('../fonts/Inconsolata-SemiBold.woff') format('woff'),
        url('../fonts/Inconsolata-SemiBold.ttf') format('truetype'),
        url('../fonts/Inconsolata-SemiBold.svg#Inconsolata-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.eot');
    src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    user-select: none !important;
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: 'Roboto';
}
h1,h2,h3,h4,h5,h6,p,ul,li {
    line-height: normal;
    padding: 0;
    margin: 0;
}
h1 {
    font-family: 'Cormorant Upright';
    font-size: 65px;
}
h2 {
    font-family: 'Cormorant Upright';
    font-size: 45px;
}
h3 {
    font-family: 'Cormorant Upright';
    font-size: 33px;
}
h4 {
    font-family: 'Cormorant Upright';
    font-size: 28px;
}
h5 {
    font-family: 'Inconsolata';
    font-size: 22px;
}
h6 {
    font-family: 'Inconsolata';
    font-size: 18px;
}
p {
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 25px;
}
li {
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 25px;
}
.container {
    max-width: 1300px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

[class*="col-"] {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

:root {
    --black-color: #000;
    --white-color: #fff;
    --light-pink: #fedee0;
    --dark-blue: #00205f;
    --gray-color: #696969;
    --wwo-bg-color: #f4f2ed;
    --border-gray: #d9d9d9;
    --primary-color: #bfaa8c;
    --primary-hover: #998363;
    --footer-bg: #021527;
}

/* Cookies accept css start */

.cookies-popup {
    position: fixed;
    width: 100%;
    padding-inline: 50px;
    padding-block: 25px;
    margin-inline: auto;
    text-align: center;
    text-align-last: center;
    background: var(--primary-hover);
    color: var(--white-color);
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    font-weight: 300;
}
.cookiesClosebtn {
    position: absolute;
    right: 10px;
    top: -10px;
}
button.cookiesCloseBtn {
    width: 30px !important;
    height: 30px !important;
    background: red !important;
    border: 1px solid red !important;
    color: #fff !important;
    line-height: 28px !important;
    text-align: center !important;
    border-radius: 100% !important;
    font-size: 12px !important;
}
.cookies-popup p {
    font-size: 16px;
    line-height: 26px;
}
.cookies-popup a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dotted;
    text-align: center;
}
.cookies-popup button {
    width: 100px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    margin-block-start: 20px;
    display: inline-block;
    background: #fff;
    border: 1px solid #fff;
    font-weight: 600;
}

/* Cookies accept css end */

/* Whatsapp chat Icon start */

.WhatsappChatIcon {
    position: fixed;
    left: 15px;
    bottom: 80px;
    width: 40px;
    height: 40px;
    z-index: 99;
    display: block;
}
.WhatsappChatIcon button {
    width: 100%;
    height: 100%;
    font-size: 30px;
    cursor: pointer;
    background: #25D366;
    color: #fff;
    border: none;
    line-height: 100%;
    text-align: center;
}

/* WEhatsapp chat icon end */

/* Scroll Top Btn css start */

.ScrollTopBtn {
    width: 40px;
    height: 40px;
    display: block;
    position: fixed;
    right: 15px;
    bottom: 30px;
    z-index: 99;
}
.ScrollTopBtn button {
    font-size: 22px;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    background: transparent !important;
}
.ScrollTopBtn button img {
    width: 100%;
    display: block;
    object-fit: cover;
}

/* Scroll Top Btn Css end */

/* Contact Icon css start */

.ContactIcon {
    width: 40px;
    height: 40px;
    display: block;
    position: fixed;
    left: 15px;
    bottom: 30px;
    z-index: 99;
}
.ContactIcon a {
    width: 100%;
    display: block;
    height: 100%;
    text-align: center;
    font-size: 30px;
    color: #fff;
    background: #41e53e;
}

/* Contact Icon css end */

/*.cadBannerHome,
.cadAboutBanner {
    background-color: #000;
}*/

/* Header CSS start */

.cadheader-mobile.cadHeaderNav {
    display: none;
}
.cadHeaderSidebar {
    position: fixed;
    background-image: url('https://www.cadandrender.com/files/sidebar-bg.png');
    background-color: var(--white-color);
    background-repeat: no-repeat;
    z-index: 99;
    border-left: 1px solid var(
    --primary-color);
    background-position: right 0 bottom 0;
    transform: translateX(100%);
    max-width: 470px;
    width: 100%;
    display: block;
    height: 100vh;
    right: 0;
    padding: 120px 70px 35px;
    top: 0;
    transition: all 0.5s ease-in-out;
}
.cadHeadaerSidebarOpen {
    transform: translateX(0);
}
.cadMenuClose {
    position: absolute;
    top: 13px;
    right: 23px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.closebtn {
    color: var(--black-color);
    font-size: 35px;
}
.cadHeaderSidebar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}
.cadHeaderSidebar ul li {
    display: block;
}
.cadHeaderSidebar ul li a, .dropdown-content a {
    text-decoration: none;
    padding-block: 8px;
    font-size: 16px;
    padding-inline-start: 15px;
    display: block;
    color: var(--white-color);
    border-block-end: 1px solid rgba(255,255,255,0.1);
    position: relative;
    transition: all 0.2s ease-in-out;
}
.cadHeaderSidebar ul li a:after, .dropdown-content a:after {
    position: absolute;
    left: 0;
    height: 0;
    content: '';
    background: #fff;
    display: block;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
}
.cadHeaderSidebar ul li a:hover:after, .cadHeaderSidebar ul li a.active:after {
    height: 100%;
}
.bar1, .bar2, .bar3 {
    height: 2px;
    background-color: #e7e7e7;
    display: block;
    margin-block-end: 6px;
}
.cadHeaderMain.sticky .bar1,
.cadHeaderMain.sticky .bar2,
.cadHeaderMain.sticky .bar3 {
    background-color: #101010;
}
.bar2 {
    width: 24%;
    margin-block-end: 0;
    float: left;
}
.bar3 {
    width: 60%;
    margin-block-end: 0;
    float: left;
    margin-left: 7px;
}
.cadHeaderMain {
    position: fixed;
    width: 100%;
    padding-block: 20px;
    transition: all 0.4s ease-in-out;
    z-index: 999;
    background: transparent;
}
.cadHeaderMain.sticky {
    padding-block: 0px;
    background: var(--white-color);
    border-bottom: 1px solid var(--primary-color);
    padding-block: 4px;
}
.cadHeaderAlign {
    align-items: center;
}
.cadHeaderNavMenus ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}
.cadHeaderNavMenus ul li {
    margin-inline: 15px;
}
.cadHeaderNavMenus ul li a {
    text-align: center;
    text-decoration: none;
    color: #e7e7e7;
    font-size: 13px;
    letter-spacing: 0.25em;
    font-family: 'Inconsolata';
    font-weight: 500;
    padding-block: 42px;
}
.cadHeaderNavMenus.col- {
    width: 29.16%;
}
.cadHeaderNavMenus.col- li.dropdown {
    position: relative;
}
.cadHeaderMain .cadHeaderNavMenus.col- li.dropdown .dropdown-content {
    border: 1px solid var(--border-gray);
    display: none;
    position: absolute;
    left: -50px;
    top: 45px;
    z-index: 9;
    min-width: 200px;
    background: #fff;
    transition: all 0.5s ease-in-out;
}
.cadHeaderMain .cadHeaderNavMenus.col- li.dropdown:hover .dropdown-content {
    display: block;
}
.cadHeaderMain.sticky .cadHeaderNavMenus.col- li.dropdown .dropdown-content {
    position: absolute;
    left: -50px;
    top: 54px;
    z-index: 9;
    min-width: 200px;
    background: #fff;
    display: none;
    transition: all 0.5s ease-in-out;
    border: 1px solid var(--primary-color);
}
.cadHeaderMain.sticky .cadHeaderNavMenus.col- li.dropdown:hover .dropdown-content {
    display: block;
}
.cadHeaderNavMenus.col- li.dropdown .dropdown-content li {
    margin-inline: 0;
}
.cadHeaderNavMenus.col- li.dropdown .dropdown-content li a {
    text-align: left;
    padding-block: 15px;
    font-weight: 300;
    font-family: 'Roboto';
    letter-spacing: unset;
    color: var(--black-color);
}
.cadHeaderNavMenus.col- li.dropdown .dropdown-content li a.active,
.cadHeaderNavMenus.col- li.dropdown .dropdown-content li a:hover {
    color: var(--primary-color);
}
.bars {
    cursor: pointer;
    max-width: 45px;
    display: block;
    width: 100%;
    height: 10px;
    margin-inline-start: auto;
}
.cadHeaderLogoCenter {
    max-width: 140px;
    display: block;
    margin-inline: auto;
}

.cadHeaderLogoCenterSticky {
    display: none;
}
.cadHeaderMain.sticky .cadHeaderLogoCenterSticky {
    display: block;
    max-width: 120px;
    display: block;
    margin-inline: auto;
}
.cadHeaderMain.sticky .cadHeaderLogoCenterSticky a {
    display: block;
}
.cadHeaderMain.sticky .cadHeaderLogoCenterSticky a img {
    display: block;
    width: 100%;
    object-fit: cover;
}
.cadHeaderLogoCenter a {
    display: block;
}
.cadHeaderMain.sticky .cadHeaderLogoCenter {
    display: none;
}
.cadHeaderMain.sticky .cadHeaderLogoCenter {
    max-width: 130px;
    width: 100%;
}
.cadHeaderMain.sticky .cadHeaderLogoCenter img {
    width: 100%;
}
.cadHeaderLogoCenter img {
    width: 100%;
    display: block;
    object-fit: cover;
}
.cadHeaderSampleBtn {
    max-width: 180px;
    display: block;
    margin-inline-end: auto;
    width: 100%;
}
.cadHeaderSampleBtn a {
    color: #e7e7e7;
    text-decoration: none;
    display: inline-block;
    font-family: 'Inconsolata';
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    letter-spacing: 0.25em;
    font-weight: 500;
}

.cadHeaderMain.sticky .cadHeaderSampleBtn a,
.cadHeaderMain.sticky .cadHeaderNavMenus ul li a {
    color: #101010;
}

.cadHeaderMain.sticky .cadHeaderNavMenus ul li a.active {
    color: var(--primary-color);
}

.cadHeaderSampleBtn a:hover {
    color: var(--primary-color);
}
.cadHeaderSidebarMain-logo {
    max-width: 200px;
    width: 100%;
    display: block;
    margin-inline-end: auto;
}
.cadHeaderSidebarMain-logo img {
    width: 100%;
    display: block;
    object-fit: cover;
}
.cadHeaderSidebarMain ul {
    margin-top: 40px;
}
.cadHeaderSidebarMain ul li {
    margin-block-end: 10px;
}
.cadHeaderSidebarMain ul li:last-child {
    margin-block-end: 0;
}
.cadHeaderSidebarMain ul li a,
.cadHeaderSidebarMain ul li p {
    padding: 0;
    color: #404040;
    font-weight: 100;
    font-size: 16px;
    line-height: 26px;
}
.cadHeaderSidebarMain ul li a:hover:after {
    display: none;
}
.cadHeaderSidebarMain ul.cadHeaderSidebarSocialMedia {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.cadHeaderSidebarMain ul.cadHeaderSidebarSocialMedia li {
    margin-bottom: 0;
    margin-inline-end: 20px;
}
.cadHeaderSidebarMain ul.cadHeaderSidebarSocialMedia li:last-child {
    margin-inline-end: 0;
}
.cadHeaderSidebarMain ul.cadHeaderSidebarSocialMedia li a {
    color: #000;
    font-size: 16px;
}


.cadHeaderNavMenus ul li a.active,
.cadHeaderNavMenus ul li a:hover {
    color: var(--primary-hover);
}

/* Header CSS end */

/* Footer css start */

.cadFooterMain {
    position: relative;
    background: var(--footer-bg);
}
.cadFooterTop {
    padding-block: 60px;
    border-block-end: 1px solid #3e3e3e;
}
.cadFooterMain .container {
    max-width: 1130px;
}
.cadFooterLogo {
    width: 100%;
    display: block;
}
.cadFooterLogo a {
    display: block;
}
.cadFooterLogo a img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.cadFooterOne p {
    color: #ccc;
    text-align: center;
    margin-block: 15px;
}
.cadFooterSocials ul {
    justify-content: center;
    display: flex;
    list-style: none;
    align-items: center;
}
.cadFooterSocials ul li {
    margin-inline: 10px;
}
.cadFooterSocials ul li a {
    transition: all 0.3s ease-in-out;
}
.cadFooterSocials ul li a i.fa {
    color: #ccc;
    font-size: 16px;
}
.cadFooterOne h2 {
    font-family: 'Raleway';
    color: #ccc;
    font-weight: 400;
    margin-block-end: 20px;
}
.cadFooterOneInner {
    max-width: 150px;
    display: block;
    margin-inline: auto;
    width: 100%;
}
.cadFooterOneInner h4,
.cadFooterContactInner h4 {
    font-weight: 300;
    color: var(--white-color);
    margin-block-end: 25px;
}
.cadFooterOne ul.cadFooterNav {
    display: flex;
    flex-direction: column;
    list-style: none;
}
.cadFooterOne ul.cadFooterNav a {
    color: #ccc;
    font-weight: 300;
    text-decoration: none;
    display: inline-block;
    margin-block-end: 15px;
    position: relative;
    font-size: 15px;
    transition: all 0.5s ease-in-out;
}
.cadFooterOne ul.cadFooterNav a.active {
    color: var(--primary-color);
}
.cadFooterOne ul.cadFooterNav a:hover {
    color: var(--primary-color);
}

.cadFooterBtm {
    padding-block: 25px;
}
.cadFooterBtm p {
    text-align: center;
    font-size: 15px;
    color: #ccc;
    font-weight: 300;
}
.cadFooterBtm p a {
    color: #ccc;
}
.cadFooterContact ul li {
    margin-block-end: 10px;
    display: flex;
    align-items: baseline;
    color: #ccc !important;
}
.cadFooterContact ul li i.fa {
    margin-right: 10px;
}
.cadFooterContact ul li a,
.cadFooterContact ul li span {
    color: #ccc !important;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
    transition: all 0.5s ease-in-out;
}
.cadFooterContact ul li a:hover {
    color: var(--white-color) !important;
}

/* Footer css end */



/* Home Banner css start */

.cadBannerHome {
    width: 100%;
    display: block;
    position: relative;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('https://www.cadandrender.com/files/loading.gif');
    background-repeat: no-repeat;
    background-position: center;
}
.cadBannerHome video.videoTag {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}
.cadBannerHome h1 {
    position: absolute;
    left: 50%;
    font-family: 'Cormorant Upright';
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    line-height: 65%;
    text-align: center;
    color: var(--black-color);
    font-weight: bold;
    width: 100%;
    text-shadow: 1px 1px 0px #ffffff;
}
span.cadbannerAndSign {
    font-family: auto;
    font-size: 60px !important;
}
.cadBannerHome h1 span.cadBannerSubtitle {
    font-size: 28px;
    font-family: 'Roboto';
}

/* Home Banner css end */

/* CadAbout section css start */

.cadAbout {
    padding-block: 70px;
}
.cadAboutUsMainBanner .cadContactBannerContent h1 {
    color: #e7e7e7 !important;
}
.cadAboutTitleImg {
    max-width: 85px;
    margin-inline: auto;
    display: block;
    margin-block-end: 50px;
}
.cadAboutTitleImg img {
    width: 100%;
    display: block;
}
.cadAbout h6 {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.25em;
    text-align: center;
    color: var(--primary-color);
    position: relative;
    z-index: 9;
}
.cadAbout h2 {
    text-align: center;
    font-weight: 500;
    position: relative;
    color: var(--black-color);
    margin-block-end: 30px;
}
.cadAboutHome h2 {
    background-image: url('https://www.cadandrender.com/files/title-bg.png');
    background-size: contain;
    background-position: center 0px;
    background-repeat: no-repeat;
    padding-block-start: 30px;
    margin-top: -30px;
}
.cadAbout .container {
    position: relative;
    max-width: 1130px;
    width: 100%;
}
.cadAbout p {
    text-align-last: center;
    font-size: 17px;
    font-weight: 100;
    line-height: 27px;
    color: #404040;
}
.cadAboutBtn {
    max-width: 190px;
    width: 100%;
    display: block;
    margin-inline: auto;
    margin-block-start: 40px;
}
.cadAboutBtn a {
    text-decoration: none;
    font-size: 15px;
    padding: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--white-color);
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    display: block;
    font-family: 'Inconsolata';
    transition: all 0.5s ease-in-out;
    letter-spacing: 0.25em;
}
.cadAboutBtn a:hover {
    border: 1px solid var(--primary-hover);
    color: var(--white-color);
    background: var(--primary-hover);
}

/* CadAbout section css end */


/* CadServices css start */

.cadServices {
    background: var(--wwo-bg-color);
    padding-block-start: 70px;
    padding-block-end: 70px;
}
.cadServices .container {
    max-width: 1130px;
}
.cadServices h2 {
    margin-block-end: 70px;
}
.cadServicesInner {
    flex-wrap: wrap;
    justify-content: center;
}
.cadServicesImgSecInner {
    position: relative;
    border-bottom: 7px solid #bfaa8c;
}
.cadServicesImgSecInner:after {
    position: absolute;
    content: '';
    top: 7px;
    width: 7px;
    right: -7px;
    height: 100%;
    background: #bfaa8c;
}
.ImgInnerRight:after {
    left: -7px;
}
.cadServicesInnerAlign {
    margin-block: 30px;
    margin-inline: 0;
    align-items: center;
}
.cadServicesImgsec {
    padding-inline: 0;
}
.cadServicesContent {
    padding-inline: 70px;
}
.cadServicesImgSecInner img,
.cadServicesImgSecInner video.videoTag {
    width: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 1/1;
}
.cadServicesContent h4 span {
    font-family: auto;
}
.cadServicesContent p {
    text-align-last: left;
    font-size: 15px;
    color: #404040;
    font-weight: 300;
    line-height: 25px;
    margin-block: 30px;
}
.cadServicesContent .cadAboutBtn {
    margin-top: 25px;
    margin-left: 0;
}

/* CadServices css end */

/* Cad Why choose us css start */

.cadWhyChooseUS {
    background-size: cover;
    background-position: revert;
    background-repeat: no-repeat;
    background-color: #021c33;
}
.cadWhyChooseUS .container {
    max-width: 1130px;
}
.cadWhyChooseUS h2 {
    margin-block-end: 50px;
    color: var(--white-color);
}
.cadWhyChooseUS p {
    padding-inline: 0;
    margin-block-end: 25px;
    color: var(--white-color);
    text-align: center;
    font-size: 16px;
}
.cadWhyChooseUS p:last-child {
    margin-block-end: 0;
}

/* Cad Why choose us css end */

/* CadContactForm css start */

.cadContactForm {
    background: var(--wwo-bg-color);
    position: relative;
}
.cadContactForm .container {
    max-width: 1130px;
}
.cadContactForm h2 {
	margin-block-end: 70px;
}
.cadContactFormImg img {
    width: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 1/1;
}
.cadContactFormInput {
    position: relative;
    padding-inline-end: 100px;
    padding-inline-start: 30px;
}
.cadContactFormInput form {
    margin-block-start: 25px;
}
.inputtypes input, .inputtypes textarea {
    width: 100%;
    font-size: 13px;
    line-height: 26px;
    padding: 15px 10px;
    outline: none;
    font-family: 'Roboto' !important;
    border: 1px solid #b3b3b3;
    color: #858585 !important;
    font-weight: 300;
    background: transparent;
}
.inputtypes textarea {
    resize: vertical;
}
.inputtypes {
    margin-block-end: 25px;
    position: relative;
}
.inputtypes:last-child {
	margin-block-end: 0;
}
.submitform {
    max-width: 190px;
    display: block;
    margin-block-start: 40px;
    width: 100%;
}
.submitform input {
    width: 100%;
    font-size: 15px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(
    --white-color);
    padding: 20px;
    cursor: pointer;
    font-family: 'Inconsolata';
    font-weight: 300;
    letter-spacing: 0.25em;
    transition: all 0.5s ease-in-out;
}

.submitform input:hover {
    background: var(--primary-hover);
    border: 1px solid var(--primary-hover);
}
.inputtypes input::-webkit-input-placeholder, .inputtypes textarea::-webkit-input-placeholder  { /* Edge */
  color: #858585;
  font-family: 'Roboto' !important;
  font-size: 13px;
  line-height: 26px;
}

.inputtypes input:-ms-input-placeholder, .inputtypes textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #858585;
  font-family: 'Roboto' !important;
  font-size: 13px;
  line-height: 26px;
}

.inputtypes input::placeholder, .inputtypes textarea::placeholder {
  color: #858585;
  font-family: 'Roboto' !important;
  font-size: 13px;
  line-height: 26px;
}
input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #858585;
    transition: background-color 5000s ease-in-out 0s;
    font-size: 13px;
    line-height: 26px;
}
.react-tel-input .form-control {
    background: transparent !important;
    width: 100% !important;
    height: 51px !important;
    border: 1px solid #b3b3b3 !important;
    border-radius: 0 !important;
    color: #858585 !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
}

span.formErrorsShow {
    color: red;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -20px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
  
  /* Add these styles to create the popup box */
  .popup {
    background-color: white;
    padding: 30px;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
}
.overlay-close-btn {
    display: block;
    width: 100%;
}

.popup h3 {
    color: var(--black-color) !important;
    font-weight: bold !important;
    margin-block-start: 20px;
    text-transform: capitalize;
}
.popup p {
    font-size: 16px;
    line-height: 26px;
    margin-block: 12px;
    font-weight: 300;
    color: #404040;
}

.overlay-close-btn button {
    background: var(--primary-hover);
    border: 1px solid var(--primary-hover);
    color: #fff;
    padding: 18px;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 0;
    cursor: pointer;
    text-transform: uppercase;
}
.popup-check-right {
    max-width: 60px;
    width: 100%;
    display: block;
    margin-inline: auto;
    margin-top: -60px;
}
.popup-check-right i.fa {
    background: #000;
    color: #fff;
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    font-size: 22px;
    margin-inline: auto;
}
.loading-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.loading-spinner {
    max-width: 100px;
    width: 100%;
    max-height: 100px;
    height: 100%;
}

.loading-spinner img {
    width: 100%;
    display: block;
    object-fit: contain;
}

/* CadContactForm css end */

/* CadGallery Css start */

.cadGallery .container {
    max-width: 1200px;
}
.cadGallery h2 {
    margin-block-end: 60px;
}
.img-reflection {
  width: 100% !important;
  position: relative;
}

.slick-slide img {
    display: block;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 1/1;
}

.slick-slide .reflectionImage {
  background: url(https://www.cadandrender.com/files/tree.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100% !important;
  position: relative;
  aspect-ratio: 5/3;
}


img.sliderImageRef {
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,0),white);
    opacity: 0.15;
    transform: scaleY(-1);
    border: 1px solid var(--border-gray);
}

.slick-prev {
    left: 15px !important;
    z-index: 99;
}
.slick-next {
    right: 15px !important;
    z-index: 99;
}
.slick-next:before {
    content: url('https://www.cadandrender.com/files/arrow-slider-blk-right.png') !important;
}

.slick-prev:before {
    content: url('https://www.cadandrender.com/files/arrow-slider-blk-left.png') !important;
}

.slick-slide:not(.slick-current) {
  transform: scale(0.85);
}


/* CadGallery css end */

/* cadTestimonial css start */

.cadTestimonials {
    border-bottom: 1px solid var(--primary-color);
}
.cadAboutTestimonials {
    border-bottom: none;
}
.cadTestimonials .container {
    max-width: 1140px;
}
.cadTestimonialAlign {
    align-items: center;
    margin-block-start: 30px;
    padding-inline: 150px;
    flex-direction: column;
}
.cadTestimonialImg {
    width: 110px;
    border-radius: 100%;
    display: block;
    height: 110px;
    margin-inline: auto;
}
.cadTestimonialImg img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.cadTestimonialInner h3 {
    text-align: center;
}
.cadTestimonialInner p {
    text-align: center;
    text-align-last: center;
    margin-block-end: 15px;
}
.cadTestimonialInner p span {
    font-weight: 500;
    font-size: 20px;
    color: #000;
}
.swiper-button-prev {
    color: var(--black-color) !important;
    left: 0px !important;
}
.swiper-button-next {
    right: 0 !important;
    color: var(--black-color) !important;
    transform: rotate(180deg) !important;
}
p.cadTestimonialTitle {
    margin-block-end: 0;
    font-size: 24px;
    color: #000;
}
.swiper-button-prev:after, .swiper-button-next:after {
    content: url('https://www.cadandrender.com/files/arrow-slider-blk.png') !important;
    background: transparent;
}
.clientReviews {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: 25px;
    margin-block-end: 15px;
}
.clientReviews i.fa {
    color: #fdcc0d;
    font-size: 16px;
    margin-inline: 2px;
}

/* cadTestimonial css end */

/* projects completed css start */

.cadCompletedProjects {
    background: var(--wwo-bg-color);
    padding-block: 70px;
}
.cadCompletedProjectsOuter h2 {
    text-align: center;
    text-transform: uppercase;
    margin-block-end: 30px;
}
.cadCompletedProjectsOuter {
    text-align: center;
}
.cadCompletedProjectsOuter span {
    font-size: 50px;
    font-family: 'Roboto';
    font-weight: 500;
    text-shadow: 0px 0px 5px var(--primary-color);
}

/* projects completed css end */

/* cadAbout page Banner css start */

.cadAboutBanner img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.cadAboutContent .container {
    max-width: 1130px;
}
.cadAboutContent p {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
    text-align-last: center;
    color: var(--black-color);
    font-weight: 300;
}

/* cadAabout Page Banner css end */

/* cadAbout Owner css start */

.cadAboutBanner {
    width: 100%;
    display: block;
    position: relative;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('https://www.cadandrender.com/files/loading.gif');
    background-repeat: no-repeat;
    background-position: center;
}
.cadAboutBanner .cadContactBannerContent {
    top: 80%;
}
.cadAboutOwner .container {
    max-width: 1130px;
}
.cadContactBannerContent {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cadAboutBanner .cadContactBannerContent h1 {
    color: #e7e7e7;
    text-shadow: 1px 1px 0 #000;
}
.cadAboutOwnerLeftInner, 
.cadAboutOwnerRightInner {
    padding: 20px;
    border: 1px solid var(--primary-color);
    min-height: 1px;
    height: 100%;
}
.cadAboutOwner {
    margin-block-end: 40px;
}
.cadAboutParas {
    padding-block-start: 0;
}
.cadAboutContent {
    margin-block: 40px;
}
.cadAboutOwnerImg {
    width: 140px;
    height: 140px;
    display: block;
    margin-inline: auto;
}
.cadAboutOwnerImg img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    padding: 2px;
    border-radius: 100%;
    border: 1px solid #bfaa8c;
}
.cadAboutOwnerLeft h3 {
    text-align: center;
    margin-block-start: 20px;
    color: var(--black-color);
}
.cadAboutOwnerLeft h4 {
    text-align: center;
    font-size: 20px;
    margin-block: 20px;
    font-weight: 700;
    color: var(--black-color);
}
.cadAboutOwnerLeft h4 span {
    font-family: auto;
}
.cadAboutOwnerLeft p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: justify;
    text-align-last: left;
    color: var(--black-color);
    margin-bottom: 10px;
}
.cadAboutOwnerLeft p:last-child {
    margin-bottom: 0;
}
.cadAboutParas p {
    text-align-last: left;
    margin-block-end: 20px;
    font-size: 16px;
}
.cadAboutParas p:last-child {
    margin-block-end: 0;
}

/* cadAbout Owner css end */

/* cadPayment css start */

.cadPaymentMethods .container {
    max-width: 1130px;
}
.cadPaymentAlign {
    align-items: center;
    margin-block-start: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.cadPaymentOptions {
    width: calc(25% - 50px);
    margin-inline: 25px;
    margin-block: 25px;
    display: block;
}
.cadPaymentOptions img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cadPaymentMethods h4 {
    text-align: center;
    font-weight: 500;
    color: var(--black-color);
}
.cadPaymentMobile {
    display: none;
}

/* cadPayment css end */


/* Contact Page Banner css start */

.cadContactBanner .cadContactBannerContent {
    top: 80%;
}
.cadContactBanner .cadContactBannerContent h1 {
    color: var(--black-color);
    text-shadow: 0.3px 0.3px 0 #fff;
}
.cadContactBannerContent h1 {
    color: var(--black-color);
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0.5px 0.5px 0px #ffffff;
}
.cadContactPageForm h2 {
    display: none;
}
.cadContactMap {
    width: 100%;
    display: block;
    height: 400px;
}
.cadContactMap iframe {
    width: 100%;
    height: 100%;
    display: block;
    border: none;
}

/* Contact Page Banner css end */

/* Cad Our Services page css start */

.cadOurServices .cadContactBannerContent {
    top: 50%;
}
.cadOurServices .cadContactBannerContent h1 {
    color: var(--black-color);
    text-shadow: 1px 1px 0 #b19174;
}
.cadOurServices img {
    width: 100%;
    display: block;
    object-fit: cover;
}
.cadServicesInnerImage video.videoTag {
    width: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 1/1;
}
.cadOurServices.container {
    max-width: 1130px;
}
.cadServicesMainInner {
    display: flex;
    padding-block: 70px;
}
.cadServicesInnerLeft {
    display: block;
}
.cadServicesInnerLeft img {
    width: 100%;
    display: block;
    object-fit: cover;
}
.cadServicesInnerImage {
    border-bottom: 7px solid #bfaa8c;
    position: relative;
}
.cadServicesInnerImage:after {
    position: absolute;
    content: '';
    background: #bfaa8c;
    width: 7px;
    right: -7px;
    height: 100%;
    top: 7px;
}
.cadServicesInnerImageAfter:after {
    left: -7px;
}
.cadServicesInnerRight {
    display: block;
}
.cadServicesInnerLeft,
.cadServicesInnerRight {
    display: flex;   
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cadServicesMainInner.servicesBlack.row {
    background: var(--wwo-bg-color);
}
.cadServicesInnerRight {
    padding-block: 0;
    padding-inline: 60px;
}
.cadServicesInnerRight h3 {
    text-align: left !important;
    width: 100%;
    margin-block-end: 20px;
}
.cadServicesInnerRight h3 span {
    font-family: auto !important;
}
.cadServicesInnerRight p {
    text-align-last: left;
    margin-block-end: 20px;
}
.cadServicesInnerRight p:last-child {
    margin-block-end: 0;
}
.cadServicesInnerRight .cadAboutBtn {
    margin-left: 0;
    margin-top : 20px;
}

/* Cad Our Services page css end */


/* cad FAQ css start */

.cadFAQ {
    padding-block: 60px;
}
.cadFaqBanner .cadContactBannerContent h1 {
    color: var(--black-color);
    text-shadow: 1px 1px 0 #fff;
}
.cadFaqBanner .cadContactBannerContent {
    top: 50%;
}
.cadFaqAcc .container {
    max-width: 1130px;
}
.cad-faq-accordian {
    margin-bottom: 70px;
}
.cad-faq-main .cad-testimonials-header span {
    max-width: 80px;
    text-align: center;
}
.cad-faq-accordian-item {
    margin-block-end: 15px;
}
.cad-faq-accordian-item:last-child {
    margin-block-end: 0;
}
.cad-faq-accordian-item.active button.cad-faq-accordian-question {
    background: #998363;
    color: var(--white-color);
    border: 1px solid #998363;
    transition: all 0.5s ease-in-out;
    font-family: 'Cormorant Upright';
    text-transform: uppercase;
    font-size: 19px;
    line-height: 29px;
}
button.cad-faq-accordian-question {
    padding-inline-start: 20px;
    padding-inline-end: 45px;
    padding-block: 10px;
    font-size: 19px;
    line-height: 29px;
    text-align: left;
    font-family: 'Cormorant Upright';
    color: #998363;
    background: transparent;
    border: 1px solid #998363;
    outline: none;
    display: block;
    width: 100%;
    position: relative;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}
.cad-faq-accordian-item.active button.cad-faq-accordian-question:before {
	content: '\f106';
	font-family: 'FontAwesome';
}
button.cad-faq-accordian-question:before {
    position: absolute;
    right: 20px;
    top: 50%;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 20px;
    transform: translateY(-50%);
}
.cad-faq-accordian-answer.active {
    font-size: 16px;
    line-height: 26px;
    padding-block: 10px;
    opacity: 1;
    height: auto;
    border: 1px solid #998363;
    padding-inline: 20px;
}
.cad-faq-accordian-answer {
	height: 0;
	overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

/* cad FAQ css end */


/* CAD Terms and Conditions page css start */

.cadTermsConditionsPolicy .container {
    max-width: 1130px;
}
.cadTerms-conditionBanner .cadContactBannerContent {
    top: 50%;
}
.cadTermsConditionsPolicy {
    padding-block-end: 70px;
}
p.cadTermsContent {
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    color: var(--gray-color);
}
.cadTerms-conditionBanner .cadContactBannerContent h1 {
    color: var(--black-color);
    text-shadow: 1px 1px 0 var(--white-color);
}
h4.cadTemsAccessHeading {
    font-weight: 600;
    text-align: justify;
    font-size: 22px;
    line-height: 34px;
    margin-block: 25px;
    color: var(--black-color);
    text-transform: uppercase;
}
p.cadTermsPaymentPara {
    margin-block-end: 10px;
    text-align: justify;
    font-size: 16px;
    line-height: 26px;
    font-family: 'Roboto';
    font-weight: 300;
}
.cadTermsConditionsPolicy ol li {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    font-family: 'Roboto';
    margin-block-end: 12px;
}

/* CAD terms and conditions page css end */


/* phonepay payment css start */

.cadPhonePayMethod .container {
    max-width: 1000px;
}

/* phonepay payment css end */



/* Gallery slick slider css start */

.swiper-button-prev, .swiper-button-next {
    color: #000 !important;
    font-size: 15px !important;
    padding-block: 40px;
    padding-inline: 22px;
}
.swiper-button-prev::after {
    font-size: 24px !important;
}
.swiper-button-next::after {
    font-size: 24px !important;
}

/* Gallery slick slider css end */


/* Photorealistic Rendering page css start */

.cadPhotoRealisticMain p {
    margin-block-end: 25px;
}
.cadPhotoRealisticMain p:last-child {
    margin-block-end: 0;
}
.cadPhotoRealistic {
    background-color: #021c33;
}
.cadPhotoRealisticMain {
    padding-block: 50px;
}
.cadPhotoRealistic h2 {
    color: var(--white-color);
    margin-block-end: 50px;
}
.cadPhotoRealistic p {
    margin-block-end: 20px;
    text-align-last: center;
    color: var(--white-color);
    font-weight: 100;
    line-height: 27px;
    font-size: 16px;
    text-align: center;
}
.cadPhotoRealistic p:last-child {
    margin-block-end: 0;
}
p.cadPhotoRealisticP {
    margin-block-end: 0;
}
.cadPhotoRealisticContent p {
    text-align-last: left;
    text-align: justify;
}
.cadPhotoRealisticAlign {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-block-start: 50px;
}
.cadPhotRealisticImageInner {
    width: 100%;
    display: block;
}
.cadPhotRealisticImageInner img {
    display: block;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1/1;
}
.cadModelingMain h1 span,
.cadModelingMain h2 span {
    font-family: auto;
}
.cadRealisticBanner .cadContactBannerContent {
    top: 93%;
}
.cadRealisticGallery {
    padding-top: 0;
}

/* Photorealistic Rendering page css end */

/* 3D animation video page css start */

.cadServicesAnimationBanner .cadContactBannerContent h1 {
    color: #e7e7e7;
    text-shadow: 0.5px 0.5px 0 #000;
}
.cadServicesAnimationBanner video {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}
p.animationP {
    margin-block-start: 50px;
}
.cadEditingBanner .cadContactBannerContent {
    top: 70%;
}

/* 3D animation video page css end */

/* Gallery Page css start */

.cadGalleryBanner .cadContactBannerContent {
    top: 50%;
}
.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}
.tabcontentGallery {
    width: calc(25% - 14px);
    padding: 0px;
    margin: 7px;
    border-radius: 10px;
    box-shadow: 0 0 5px #d1d1d1;
    overflow: hidden;
}
.tabcontentGalleryInner {
    width: 100%;
    display: block;
}
.tabcontentGalleryInner img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
}
.disable-scroll {
    overflow: hidden;
}
button.loadmoreGalleryBtn {
    border: 1px solid var(--primary-color);
    color: var(--white-color);
    background: var(--primary-color);
    font-size: 15px;
    outline: none;
    padding: 20px;
    text-align: center;
    max-width: 190px;
    width: 100%;
    display: block;
    margin-block-start: 30px;
    margin-inline: auto;
    cursor: pointer;
    font-family: 'Inconsolata';
    transition: all 0.5s ease-in-out;
    letter-spacing: 0.25em;
    margin-block-end: 30px;
}
button.loadmoreGalleryBtn:hover {
	background: var(--primary-hover);
	border: 1px solid var(--primary-hover);
}
.galleryVideosContentInner {
    padding: 0;
    width: calc(33.33% - 2px);
    margin: 1px;
}

.cadGalleyImagesMain {
    background: var(--wwo-bg-color);
}
.cadGalleyImagesMain h2,
.cadGalleyVideosMain h2 {
    margin-block-end: 60px;
}
.cadGalleyImagesMain h3 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
}
.cadGalleyImagesMain h3 span {
    font-family: 'FontAwesome';
}
.cadGalleryRealistic {
    margin-bottom: 70px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background color */
  z-index: 1000; /* Ensure the backdrop is above other content */
}
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    z-index: 9999;
    height: 90%;
}
.modal-content {
    display: block;
    height: 100%;
    position: relative;
}
.nav-buttons button.btnLeft {
    position: absolute;
    left: -40px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}
.nav-buttons button.btnRight {
    position: absolute;
    right: -40px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}
.modal-content img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}
.modal-content span.close {
    position: absolute;
    right: -40px;
    top: 0px;
    color: var(--black-color);
    font-size: 30px;
    padding: 5px;
    background: var(--primary-color);
    text-align: center;
    height: 40px;
    width: 40px;
    line-height: 25px;
    cursor: pointer;
}

/* Gallery page css end */

/* 404 page css start */

.cadPNFContent .container {
    max-width: 1130px;
}
.cadPNFBanner .cadContactBannerContent {
    top: 50%;
}
.cadPNFContent h2 span {
	font-family: auto;
}
.cadPNFContent h3 {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--black-color);
}

/* 404 page css end * /

/* payment single page css start */

.cad-payment-pg-scan-img {
    position: relative;
}
.cad-payment-tag {
    position: absolute;
    background: #bfaa8c;
    color: #000;
    padding-block: 5px;
    padding-inline: 50px;
    bottom: 8%;
    left: 50px;
}
.cad-payment-tag:after {
    position: absolute;
    content: '';
    background: #bfaa8c;
    width: 25px;
    left: 90px;
    top: -20px;
    transform: rotate(90deg);
    height: 20px;
    clip-path: polygon(100% 0%, 0 100%, 100% 100%);
}
.cad-payment-pg-scan-img img {
    width: 100%;
    display: block;
    object-fit: cover;
}
.cad-payment-pg-inner h1 {
    text-align: center;
    text-transform: uppercase;
}
.cad-payment-pg-align {
    align-items: center;
    flex-wrap: wrap;
}

/* payment single page css end */


/* header black css start */

.cadHeaderBlack .cadHeaderSampleBtn a {
    color: var(--black-color);
}
.cadHeaderBlack .cadHeaderNavMenus ul li a {
    color: var(--black-color);
}
.cadHeaderBlack .cadHeaderNavMenus ul li a.active {
    color: var(--primary-color);
}
.cadHeaderBlack .bar1, .cadHeaderBlack .bar2, .cadHeaderBlack .bar3 {
    background-color: var(--black-color);
}

/* header black css end */
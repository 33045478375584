
@media screen and (max-width: 991px) {
    .cadHeaderNavMenus.col- {
    display: none;
}
.cadheader-full.cadHeaderNav {
    display: none;
}
.cadheader-mobile.cadHeaderNav {
    display: block;
}
.cadHeaderSidebar {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 70px 0 0 0;
    overflow-y: auto;
    background: var(--black-color);
}
.cadHeaderNavSampleBtn.col-1 {
    width: 20%;
}
.cadHeaderMenu.col-1 {
    width: 20%;
}
.cadHeaderLogo.col-3 {
    width: 60%;
}
.cadHeaderSidebar ul li a, .dropdown-content a {
    padding-block: 20px;
    padding-inline-start: 15px;
    color: var(--white-color);
    padding-inline-end: 15px;
    text-align: center;
}
.cadHeaderSidebar ul li a.active {
    background: var(--primary-color);
    color: var(--white-color);
}
.cadHeaderSidebar ul li.dropdown .dropdown-content {
    display: block;
}
.closebtn {
    color: var(--white-color);
}
li.dropdown i {
    display: none;
}
.cadContactFormInput {
    padding-inline-end: 30px;
}
}

@media screen and (max-width: 767px) {
    .cadHeaderLogoCenter {
    max-width: 100px;
}
.cadHeaderMain.sticky .cadHeaderLogoCenterSticky {
    max-width: 100px;
}
.cadServicesInnerAlign {    
    flex-wrap: wrap;
}
.cadServicesImgsec {
    width: 100%;
    margin-bottom: 30px;
}
.cadServicesContent {
    width: 100%;
}
.cadServices h2 {
    margin-block-end: 30px;
}
.cadServicesImgSecInner {
    padding-inline-end: 7px;
}
.cadServicesImgSecInner:after {
    right: 0px;
}
.ImgInnerRight:after {
    left: 0px;
}
.cadServicesContent {
    padding-inline: 15px;
}
.cadServicesContent h4 {
    text-align: center;
}
.cadServicesContent p {
    text-align: center;
    text-align-last: center;
}
.cadAbout p {
    text-align: center;
}
.cadServicesContent .cadAboutBtn {
    margin-inline: auto;
}
.cadServicesRevers {
    flex-direction: column-reverse;
}
.row.cadContactFormAlign {
    flex-wrap: wrap;
}
.cadContactFormContent {
    width: 100%;
    margin-bottom: 30px;
}
.cadContactFormInput {
    width: 100%;
    padding-inline: 30px;
}
.cadContactFormInput h4 {
    text-align: center;
}
.row.cadAboutOwnerAlign {
    flex-wrap: wrap;
}
.cadAboutOwnerLeft {
    width: 100%;
    margin-block: 10px;
}
.cadAboutOwnerLeft p {
    text-align-last: center;
}
.cadAboutParas p {
    text-align-last: center;
    text-align: center;
}
.cadAboutContent {
    border-bottom: 1px solid var(--primary-color);
    padding-block-end: 40px;
    margin-bottom: 0;
}
.cadServicesInnerImage {
    padding-inline-end: 7px;
    margin-block-end: 30px;
}
.cadServicesInnerImage:after {
    right: 0px;
}
.cadServicesInnerImageAfter:after {
    left: 0px;
}
.cadServicesMainInner {
    flex-wrap: wrap;
}
.servicesReverse {
    flex-direction: column-reverse;
}
.cadServicesInnerLeft, .cadServicesInnerRight {
    width: 100%;
}
.cadServicesInnerRight h3 {
    text-align: center !important;
}
.cadServicesInnerRight p {
    text-align-last: center;
}
.cadServicesInnerRight .cadAboutBtn {
    margin-inline: auto;
}
.cadPhotoRealisticContent {
    padding-inline: 30px;
    width: 100%;
}
.PhotoRealisticColReverse {
    flex-direction: column-reverse;
}
.cadPhotoRealisticImage {
    width: 100%;
}
.cadPhotoRealisticContent p {
    text-align-last: center;
    text-align: center;
}
.cadPhotoRealistocPad {
    padding-inline-end: 0;
    padding-inline-start: 7px;
}
.tabcontent1GalleryInner {
    display: flex;
    flex-wrap: wrap;
}
.tabcontentGallery {
    width: calc(50% - 14px);
}
.ImgInnerRight {
    padding-right: 0;
    padding-left: 7px;
}
.cadPaymentFull {
    display: none;
}
.cadPaymentMobile {
    flex-wrap: wrap;
    display: flex;
}
.cadGalleyImagesMain h3 {
    text-align: center;
}
.modal {
    width: 100%;
}

}


@media screen and (max-width: 667px) {
    .cadFooterOne {
    width: 100%;
    margin-block: 15px;
}
.cadFooterLogo {
    width: 20%;
    margin-inline: auto;
}
.cadFooterOneInner {
    max-width: 100%;
}
.cadFooterOneInner h4, .cadFooterContactInner h4 {
    text-align: center;
}
.cadFooterOne ul.cadFooterNav li {
    text-align: center;
}
.cadFooterContact ul li {
    display: block;
    text-align: center;
}
.cadFooterTop {
    padding-block: 30px;
}
}


@media screen and (max-width: 568px) {

	h1 {
		font-size: 50px;
	}
	h2 {
		font-size: 36px;
	}
	h3 {
		font-size: 28px;
	}
    
    .cadBannerHome {
    height: 100vh;
}
.cadBannerHome video.videoTag {
    height: 100%;
}
.cadFooterLogo {
    width: 30%;
}
.galleryVideosContentInner {
    width: 100%;
}
.tabcontentGallery {
    width: 100%;
}
.modal {
    max-width: 85%;
}
.cadContactForm h2 {
    margin-block-end: 30px;
}
.cadAbout {
    padding-block: 40px;
}
.cadServices {
    padding-block-start: 40px;
    padding-block-end: 40px;
}
.cadServicesMainInner {
    padding-block: 40px;
}
.cadServicesInnerRight {
    padding-inline: 30px;
}
.cadServicesMain .cadServicesInnerRight {
    padding-block: 0;
}
.cadAboutContent {
    margin-block: 0px;
}
.cadCompletedProjectsOuter span {
    font-size: 40px;
}
.cadCompletedProjects {
    padding-block: 40px;
}

}


@media screen and (max-width: 480px) {
    h1 {
    font-size: 46px;
}
    h2 {
    font-size: 36px;
}
h3 {
    font-size: 28px;
}
.cadRealisticBanner .cadContactBannerContent {
    top: 90%;
}
    .cadHeaderLogoCenter {
    max-width: 80px;
}
.cadHeaderMain.sticky .cadHeaderLogoCenterSticky {
    max-width: 80px;
}
.cadAbout {
    padding-block: 30px;
}
.cadAboutTitleImg {
    max-width: 60px;
}
.cadAbout p {
    font-size: 15px;
    line-height: 25px;
}
.cadContactForm h2 {
    margin-block-end: 30px;
}
.cadContactFormContent {
    margin-bottom: 0;
}
.cadTestimonialAlign {
    margin-block-start: 0;
    padding-inline: 15px;
}
.cadPaymentAlign {
    margin-block-start: 30px;
    flex-wrap: wrap;
}
.cadPaymentOptions {
    width: calc(33.33% - 30px);
    margin-inline: 15px;
}
.cadAboutContent p {
    font-size: 17px;
    line-height: 27px;
}
.cadAboutOwnerLeft p {
    font-size: 15px;
    line-height: 25px;
}
.cadAboutContent p {
    font-size: 15px;
    line-height: 25px;
}
.cadServicesInnerRight {
    padding-inline: 30px;
    padding-block: 0;
}
.cadAboutOwner {
    margin-block-end: 30px;
}
.cadServicesMainInner {
    padding-block: 30px;
}
.cadServices h2 {
    margin-block-end: 0;
}
.cadWhyChooseUS h2 {
    margin-block-end: 20px;
}
.cadGallery h2 {
    margin-block-end: 20px;
}
.cadAbout h2 {
    margin-block-end: 20px;
}
.cadAboutContent {
    padding-block: 40px;
}
.cadAboutParas {
    padding-block-start: 0 !important;
}

h4.cadTemsAccessHeading {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
}
.cadTermsConditionsPolicy ol li {
    font-size: 15px;
    line-height: 25px;
}
.cadTermsConditionsPolicy {
    padding-block-end: 30px;
}
.WhatsappChatIcon {
    left: 10px;
    width: 35px;
    height: 35px;
    bottom: 75px;
}
.WhatsappChatIcon button {
    font-size: 26px;
}
.ContactIcon {
    width: 35px;
    height: 35px;
    left: 10px;
}
.ScrollTopBtn {
    width: 35px;
    height: 35px;
    right: 10px;
}
.ContactIcon a {
    font-size: 24px;
}
.slick-next {
    right: 15px !important;
}
.slick-prev {
    left: 0px !important;
}
.modal {
    max-width: 85%;
    height: 80%;
}
.cadServicesContent p {
    margin-block: 15px;
}
.cadAboutBtn {
    margin-block-start: 20px;
}
.cadCompletedProjectsOuter span {
    font-size: 36px;
}

}

@media screen and (max-width: 414px) {

    h1 {
    font-size: 40px;
}
    h2 {
    font-size: 32px;
}
h3 {
    font-size: 28px;
}
    h4 {
    font-size: 26px;
}
    .cadHeaderSampleBtn a {
    font-size: 11px;
}
.cadAboutOwnerImg {
    width: 100px;
    height: 100px;
}
.cadFooterLogo {
    width: 40%;
}
.cadPaymentOptions {
    width: calc(50% - 30px);
    margin-inline: 15px;
}
.modal {
    height: 65%;
}
.cadCompletedProjectsOuter span {
    font-size: 32px;
}
}

@media screen and (max-width: 375px) {
    .bars {
    max-width: 35px;
}
.bar2 {
    width: 35%;
}
.bar3 {
    width: 45%;
}
.modal {
    height: 60%;
    width: 80%;
}
}

@media screen and (max-width: 360px) {
    .modal {
    height: 55%;
}
}